<template>
  <div v-if="data">
    <!-- drawer component -->
    <div
      id="drawer-example"
      class="absolute top-0 right-0 border-l z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-80 dark:bg-gray-800"
      tabindex="-1"
      aria-labelledby="drawer-label"
    >
      <h5
        id="drawer-label"
        class="inline-flex items-center mb-4 text-base font-semibold text-gray-700 dark:text-gray-400"
      >
        <svg
          class="w-4 h-4 mr-2.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          /></svg
        >{{ data.orderId }}
      </h5>
      <button
        type="button"
        @click="$emit('close')"
        data-drawer-hide="drawer-example"
        aria-controls="drawer-example"
        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
      >
        <svg
          class="w-3 h-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
          />
        </svg>
        <span class="sr-only">Cerrar</span>
      </button>
      <div>
        <div class="w-full bg-white p-2 mt-2">
          <div class="sm:flex sm:space-x-3 dark:bg-gray-900 dark:text-gray-100">
            <div class="flex-shrink-0 w-full mb-6 h-12 sm:h-12 sm:w-12 sm:mb-0">
              <img
                class="object-cover object-center w-full h-full rounded dark:bg-gray-500"
                :src="`https://i.pravatar.cc/300?u=orsai-${index}`"
                alt="Neil image"
              />
            </div>
            <div class="flex flex-col space-y-4">
              <div>
                <h3 class="text-lg font-semibold">
                  {{ data.shipping.name }} {{ data.shipping.lastName }}
                </h3>
                <span class="text-sm dark:text-gray-400">10 compras</span>
              </div>
            </div>
          </div>
          <div class="space-y-1 text-sm mt-3">
            <span class="flex items-center space-x-2 justify-start">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                aria-label="Email address"
                class="w-4 h-4"
              >
                <path
                  fill="currentColor"
                  d="M274.6,25.623a32.006,32.006,0,0,0-37.2,0L16,183.766V496H496V183.766ZM464,402.693,339.97,322.96,464,226.492ZM256,51.662,454.429,193.4,311.434,304.615,256,268.979l-55.434,35.636L57.571,193.4ZM48,226.492,172.03,322.96,48,402.693ZM464,464H48V440.735L256,307.021,464,440.735Z"
                ></path>
              </svg>
              <span class="dark:text-gray-400">{{ data.shipping.email }}</span>
            </span>
            <span class="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                aria-label="Phonenumber"
                class="w-4 h-4"
              >
                <path
                  fill="currentColor"
                  d="M449.366,89.648l-.685-.428L362.088,46.559,268.625,171.176l43,57.337a88.529,88.529,0,0,1-83.115,83.114l-57.336-43L46.558,362.088l42.306,85.869.356.725.429.684a25.085,25.085,0,0,0,21.393,11.857h22.344A327.836,327.836,0,0,0,461.222,133.386V111.041A25.084,25.084,0,0,0,449.366,89.648Zm-20.144,43.738c0,163.125-132.712,295.837-295.836,295.837h-18.08L87,371.76l84.18-63.135,46.867,35.149h5.333a120.535,120.535,0,0,0,120.4-120.4v-5.333l-35.149-46.866L371.759,87l57.463,28.311Z"
                ></path>
              </svg>
              <span class="dark:text-gray-400" v-if="data.shipping.phone">{{
                data.shipping.phone.formatInternational
              }}</span>
            </span>
          </div>

          <!-- Listado de items en el carrito -->
          <div class="my-2 py-2">
            <!-- Titulo -->

            <div class="w-full bg-white">
              <p class="mt-2 font-bold text-black text-md dark:text-white">
                Carrito
                <span class="ml-2 text-sm text-gray-500 dark:text-gray-300 dark:text-white">
                  ({{ data.cart.length - 1 }})
                </span>
              </p>
              <ul class="mr-2">
                <li
                  :key="iItem"
                  v-for="(item, iItem) in data.cart.filter((it) => it.sku)"
                  class="flex items-center justify-between py-3 text-gray-800 border-b-2 border-gray-100 dark:text-gray-200 dark:border-gray-800"
                >
                  <div class="flex items-center justify-start text-sm">
                    <span> {{ item.name }} </span>
                    <!-- <span> Create wireframe </span> -->
                  </div>
                  <div class="text-sm">
                    {{ (item.price.ars * 0.5) | formatearDinero }}
                  </div>
                </li>
                <li
                  :key="iItem"
                  v-for="(item, iItem) in data.cart.filter((it) => it.sku)"
                  class="flex items-center justify-between py-3 text-gray-800 border-gray-100 dark:text-gray-200 dark:border-gray-800"
                >
                  <div class="flex items-center justify-start text-sm">
                    <span class="font-bold"> Total</span>
                    <!-- <span> Create wireframe </span> -->
                  </div>
                  <div class="text-sm">
                    {{ (data.totalAmount * 0.5) | formatearDinero }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatearDinero(value) {
      return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        maximumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>

<style></style>
